@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Exo", sans-serif !important;
  line-height: 1.3;
  color: #ebebeb;
}

input:focus {
  outline: none !important;
}

input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #556172 !important;
}

input::placeholder,
textarea::placeholder {
  color: #556172 !important;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  border: 0 !important;
  box-shadow: none !important;
  /* background: #262b3a !important; */
}
.ant-select-selector :focus,
.ant-select-selector :focus-within {
  border: 0 !important;
  box-shadow: none !important;
  /* background: #262b3a !important; */
}



.ant-input-outlined,
.ant-select-selector {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 10px !important;
}

a {
  cursor: pointer;
  transition: none;
}

img {
  width: 100%;
}

body {
  margin: 0 !important;
  color: #35373F;
  background: #050518;
}

.bg-primary-gradient {
  background: linear-gradient(270deg, #00a2e8, #53e3df);
}
.bg-secondary-gradient {
  /* background: #fd5065; */
  background: linear-gradient(270deg, #ff614b, #fd5065);
}

/* Trending */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.slider {
  /* background: white; */
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
  height: 50px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 80%;
  /* padding-left: 100px; */
}

.slider:hover .slide-track {
  animation-play-state: paused;
}

.slide-track {
  animation: scroll 25s linear infinite;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  /* min-width: 150%; */
  position: absolute;
  height: 100%;
  gap: 20px;
}

.ant-form-item-margin-offset {
  margin-bottom: 0 !important;
}

.slide {
  height: 100%;
  transition: 300ms all linear;
}

.connect-btn-condition .wallet-adapter-dropdown {
  width: 100%;
}

.connect-btn-condition .wallet-adapter-button.wallet-adapter-button-trigger {
  background: linear-gradient(90deg, #8c0bf7, #50defd) !important;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.ant-layout {
  background: #000000;
}
.ant-layout-content {
  max-width: 600px;
  width: 100%;
  margin: auto;
  padding-bottom: 100px;
}



.ant-layout .ant-layout-sider,
.ant-menu-dark.menu-main,
.ant-menu-dark.menu-main > .ant-menu {
  background: #000000;
}
.ant-menu.menu-main{
  background: #181818;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}
.ant-menu.menu-main .ant-menu-item {
  font-weight: 600;
  font-size: 14px;
}

.ant-menu.menu-main .ant-menu-item .anticon {
  font-size: 20px;
}

.ant-menu-dark.menu-launchpad-list,
.ant-menu-dark.menu-launchpad-list
  > .ant-menu
  .ant-menu.menu-main
  .ant-menu-item:first-child {
  /* padding-left: 16px !important; */
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  height: 90px !important;
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.ant-layout-header {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #000000;
  background-repeat: no-repeat !important;
  background-size: cover  !important;
}

.pre-header {
  position: fixed;
  left: 250px;
  top: 0;
  right: 0;
  background: #000000;
  width: 100%;
  height: 80px; 
  z-index: 9;
}


.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-btn {
  padding: 0;
  margin-left: -20px;
}

.ant-menu.menu-main .ant-menu-item:first-child.ant-menu-item-selected {
  background: none !important;
}

.ant-menu.menu-main .ant-menu-item {
  display: flex;
  align-items: center;
  margin-block: 0;
  padding: 0 !important;
}

.ant-menu-inline.menu-main .ant-menu-item,
.ant-menu-vertical.menu-main .ant-menu-item,
.ant-menu-inline.menu-main .ant-menu-submenu-title,
.ant-menu-vertical.menu-main .ant-menu-submenu-title {
  margin-inline: 0;
  width: 100%;
  border-radius: 0;
  color: #ffffff69;
}

.ant-menu-inline.menu-main .ant-menu-item path{
  fill: #ffffff69;
}

.ant-menu-inline.menu-main .ant-menu-item span{
  margin-inline-start: 0;
  color: #ffffff69;
}

.ant-menu-dark.menu-main .ant-menu-item-selected ,
.ant-menu-dark .menu-main > .ant-menu-item-selected ,
.ant-menu-dark.menu-main .ant-menu-item:hover  {
  background: #181818;
  color: #fff !important;
}
.ant-menu-dark.menu-main .ant-menu-item-selected span,
.ant-menu-dark .menu-main > .ant-menu-item-selected span,
.ant-menu-dark.menu-main .ant-menu-item:hover span {
  color: #fff !important;
}

.ant-menu-dark.menu-main .ant-menu-item-selected path,
.ant-menu-dark.menu-main .ant-menu-item:hover path{
  fill: #fff !important;
}

.ant-menu-inline .ant-menu-item {
  height: 70px;
  line-height: 1;
}

.wallet-adapter-button-trigger {
  background: #0098EA !important;
  border-radius: 12px !important;
  height: 50px !important;
  line-height: 50px;
}

a:hover {
  color: inherit;
}

.bg-box-analytics-inner {
  border-radius: inherit;
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-picker.ant-picker-outlined {
  border: 0 !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  /* color: #fff !important; */
}

.ant-select-selection-item {
  /* color: #fff !important; */
}

.ant-select .ant-select-arrow {
  /* color: #ff614b !important; */
}

@media screen and (max-width: 768px) {
  .wallet-adapter-button-trigger {
    border-radius: 10px !important;
    height: 40px !important;
    line-height: 40px;
    padding: 0 15px !important;
    font-size: 14px !important;
  }
  .slider {
    width: 100%;
  }
  .slide-track {
    gap: 10px;
  }

  .ant-layout .ant-layout-header,
  .ant-layout.ant-layout-footer {
    background: #101010 !important;
  }

  tc-root[data-tc-dropdown-container="true"]{
    left: auto !important;
    right: 20px !important;
  }
}

.ton-connect-button{
  width: 100% !important;
}

.ton-connect-button button{
  background: #FFF;
  border-radius: 0.7rem;
  height: 50px;
  padding: 8px 22px 8px 20px;
  justify-content: center;
  width: 100%;
  
}
.ton-connect-button tc-root div{
  width: 100% !important;
}
.ton-connect-button button [data-tc-text="true"]{
  color: #000 ;
  font-size: 16px;
}
.ton-connect-button button [data-tc-text="true"] svg path, .ton-connect-button button[data-tc-button="true"] svg path {
  fill: #000 ;
}


.bg-box-avatar{
  background: rgb(80,222,253);
  background: linear-gradient(180deg, rgba(80,222,253,1) 0%, rgba(140,11,247,1) 100%);
}

.document-box{
  background: rgb(71, 123, 255);
  background: linear-gradient(33deg, rgba(71, 123, 255, 1) 0%, rgba(88, 193, 252, 1) 100%);
}


.ant-layout-sider-children{
  max-width: 600px;
  margin: auto;
}

.your-score{
  background-image: url('assets/img/gold2.png');
  border-bottom: 1px solid #ffffff36;
}


.ant-modal-mask{
  backdrop-filter: blur(5px);
  background: #000 !important;
}

.ant-modal-content{
  background: #000 !important;
}
.ant-modal-close{
  display: none !important;
}

.ant-modal-content .checking{
  font-size: 60px;
}